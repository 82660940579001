<template>
  <a-modal width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card  :bordered="false" :title="$t('销售订单.收货地址信息')"
             :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
             :bodyStyle="{'background-color':'#fbfbfb'}">
      <table class="info-table">
        <tr>
          <td class="first">{{$t('销售订单.收货人姓名')}}：</td>
          <td class="data">
            {{ this.record.orderAddressDetail.realName}}
          </td>
          <td class="first">{{$t('销售订单.性别')}}：</td>
          <td class="data">
            {{ this.record.orderAddressDetail.sexName}}
          </td>
          <td class="first">{{$t('销售订单.手机号')}}：</td>
          <td class="data">
            {{this.record.orderAddressDetail.mobile}}
          </td>
        </tr>
        <tr>
          <td class="first">{{$t('销售订单.邮箱')}}：</td>
          <td class="data">
            {{ this.record.orderAddressDetail.email}}
          </td>
<!--          <td class="first">{{$t('销售订单.地址类型')}}：</td>
          <td class="data">
            {{ this.record.orderAddressDetail.addressTypeName}}
          </td>-->
          <td class="first">{{$t('销售订单.地区')}}：</td>
          <td class="data">
            {{this.record.orderAddressDetail.nation}}
          </td>
        </tr>
        <tr>
          <td class="first">{{$t('销售订单.详细地址')}}：</td>
          <td  colspan="5">
            {{ this.record.orderAddressDetail.areap + this.record.orderAddressDetail.areac + this.record.orderAddressDetail.areax+this.record.orderAddressDetail.detailedAddress}}
          </td>
        </tr>
      </table>
    </a-card>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('销售订单.物流公司')" prop="logisticsId" >
        <a-select style="width: 100%" v-model="form.logisticsId"  @change="logisticsChange" :placeholder="$t('通用.输入.请选择')+$t('销售订单.物流公司')">
          <a-select-option v-for="(item, index) in logisticsList"  :value="item.id" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.物流单号')" prop="logisticsNo" >
        <a-input v-model="form.logisticsNo" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('销售订单.物流单号')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.寄件人手机号')" prop="deliveryMobile" >
        <a-input v-model="form.deliveryMobile" :placeholder="$t('通用.输入.请输入')+$t('销售订单.寄件人手机号')" />
        <div class="tips">顺丰快递必填 </div>
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.发货备注')" prop="remark" >
        <a-input v-model="form.remark" type="textarea" :placeholder="$t('通用.输入.请输入')+$t('销售订单.发货备注')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('销售订单.发货')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getSell, deliverySell, updateSell } from '@/api/order/sell'
import { listLogistics } from '@/api/order/logistics'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'DeliveryForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      logisticsList: [],
      record: {
        id:null,
        orderAddressDetail: {},
      } ,
      // 表单参数
      form: {
        id: null,
        logisticsId:null,
        logisticsNo: null,
        deliveryMobile:null,
        logisticsName: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        logisticsNo: [
          { required: true, message: this.$t('销售订单.物流单号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        logisticsId: [
          { required: true, message: this.$t('销售订单.物流公司')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.record={
        id:null,
        orderAddressDetail: {},
      }
      this.form = {
        id: null,
        logisticsId:null,
        logisticsNo: null,
        deliveryMobile:null,

        logisticsName: null,
        remark: null,
      }

    },
    /** 修改按钮操作 */
    handleDelivery(id) {
      this.reset()
      this.formType = 2
      listLogistics({isSelect: 1}).then(response => {
        this.logisticsList=response.data
      });
      getSell({"id":id}).then(response => {
        this.record = response.data
        this.form.id= id
        this.open = true
        this.formTitle = this.$t('销售订单.发货')
      })
    },
    logisticsChange(value) {
      this.logisticsList.forEach(e=>{
        if (e.id === value) {
          this.form.logisticsName = e.name
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('销售订单.发货成功')
            deliverySell(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style  lang="less" scoped>
.info-table {
  tr {
    height: 50px;
  }
  td {
    display: table-cell;
    vertical-align: top;
    font-size: 16px;
  }
  .first {
    width: 120px;
  }
  .data {
    width: 280px;
  }
}
</style>
