import request from '@/utils/request'


// 查询物流公司信息列表
export function listLogistics(query) {
  return request({
    url: '/order/logistics/list',
    method: 'get',
    params: query
  })
}

// 查询物流公司信息分页
export function pageLogistics(query) {
  return request({
    url: '/order/logistics/page',
    method: 'get',
    params: query
  })
}

// 查询物流公司信息详细
export function getLogistics(data) {
  return request({
    url: '/order/logistics/detail',
    method: 'get',
    params: data
  })
}

// 新增物流公司信息
export function addLogistics(data) {
  return request({
    url: '/order/logistics/add',
    method: 'post',
    data: data
  })
}

// 修改物流公司信息
export function updateLogistics(data) {
  return request({
    url: '/order/logistics/edit',
    method: 'post',
    data: data
  })
}

// 删除物流公司信息
export function delLogistics(data) {
  return request({
    url: '/order/logistics/delete',
    method: 'post',
    data: data
  })
}
